import { gql } from '@apollo/client';
import { CalculationEvent, laytimeCalculationCalculatedFields, laytimeCalculationModel, ReversibleCalculationInfo } from 'model';

export const getLaytimeCalculationCalculatedFragment = `
    id
    permittedTime
    realLaytimeBegins
    realLaytimeBeginsOverride
    totalTimeUsed
    lessInterruptions
    netTimeUsed
    mvOnDemurrage
    timeInDemurrage
    demurrageAmount
    timeInDespatch
    despatchAmount
    despatchPerDay
    amountBeforeShare
    sharedPercentage
    laytimeCalculationResult
    hideRemarks
    quantity
    massUnit
    reversedByCalcId
`;

export type GetLaytimeCalculationCalculatedOutput = {
  laytimeCalculations: laytimeCalculationCalculatedFields[];
};

export const GET_LAYTIME_CALCULATION_CALCULATED = gql`
  query getLaytimeCalculationCalculated($where: LaytimeCalculationFilterInput) {
    laytimeCalculations(where: $where) {
      ${getLaytimeCalculationCalculatedFragment}
    }
  }
`;

export type GetCalculationEventsOutput = {
  laytimeCalculationStoryReport: {
    events: CalculationEvent[];
    reversibleCalculationInfo: ReversibleCalculationInfo;
    hasErrors: boolean;
    errorMessage?: string;
  };
};

export const GET_CALCULATION_EVENTS = gql`
  query getCalculationEvents($calculationId: UUID!) {
    laytimeCalculationStoryReport(calculationId: $calculationId) {
      laytimeCalculation {
        ${getLaytimeCalculationCalculatedFragment}
      }
      events {
        from
        to
        percentageToCount
        remarks
        timeUsedInMinutes
        cummulativeUsedTimeInMinutes
        timeUsedFormatted
        cummulativeUsedTimeFormatted
        isMilestone
      }
      reversibleCalculationInfo {
        isReversibleCalc
        isLoading
        totalPermittedTime
      }
      hasErrors
      errorMessage
    }
  }
`;

export interface GetLaytimeCalculationsOutput {
  laytimeCalculations: laytimeCalculationModel[];
}

export const getLaytimeCalculations = () => {
  const dealPortQuery = `
    id
    portId
    port {
      id
      name
    }
  `;

  const splitQuery = `
    splitQuantities {
      loadingPortId
      loadingPort {
        ${dealPortQuery}
      }
      unloadingPortId
      unloadingPort {
        ${dealPortQuery}
      }
      productId
      product {
        id
        name
      }
      incoTerm
      trafficSpecialistId
      trafficSpecialist {
        id
        profile{
          id
          initial
          fullName
        }
      }
    }
  `;

  const dealsQuery = `
    id
    name
    vesselId
    vessel {
      id
      name
    }
    currency
    ${splitQuery}
  `;
  return gql`
  query getLaytimeCalculations($where: LaytimeCalculationFilterInput, $order: [LaytimeCalculationSortInput!]) {
    laytimeCalculations(where: $where, order: $order) {
      id
      counterPartyId
      counterParty {
        id
        name
        businessUnitId
      }
      quantity
      massUnit
      dealPortId
      dealPort {
        ${dealPortQuery}
        loadingDeals {
          ${dealsQuery}
        }
        unloadingDeals {
          ${dealsQuery}
        }
      }
      ${splitQuery}
    }
  }
  `;
};

export interface GetDealLaytimeCalculations {
  laytimeCalculations: laytimeCalculationModel[];
}

export const GET_DEAL_LAYTIME_CALCULATIONS = gql`
query getDealCalculations($dealId: UUID!) {
  laytimeCalculations(where: {
    dealPort: {
      or: [
        {
          loadingDeals: {
            some: {
              id: {
                eq: $dealId
              }
            }
          }
        },
        {
          unloadingDeals: {
            some: {
              id: {
                eq: $dealId
              }
            }
          }
        }
      ]
    }
  }) {
    id
    counterPartyId
    counterParty {
      id
      name
      businessUnitId
    }
    counterPartyTypeId
    counterPartyType {
      id
      name
    }
    dealPortId
    dealPort {
      portId
      port {
        id
        name
      }
      loadingDeals {
        id
      }
      unloadingDeals {
        id
      }
    }
    laytimeCalculationResult
    despatchAmount
    demurrageAmount
    currency
    status
    statusComment
    version
  }
}
`;
