import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  AppBar,
  Toolbar,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  useTheme,
  Grid,
  TextField
} from '@mui/material';
import { AddDealInput, ADD_DEAL, UPDATE_DEAL, GetLaytimeCalculationsOutput, getLaytimeCalculations } from 'api';
import { t } from 'i18next';
import {
  applicationUserModel,
  counterPartyTypeModel,
  dealModel,
  dealPortDeal,
  portModel,
  productModel,
  vesselModel
} from 'model';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  TXT_CANCEL,
  TXT_SAVING,
  TXT_SAVE,
  TXT_CREATE_QUICK_CALCULATION,
  TXT_UPDATE_QUICK_CALC,
  TXT_UPDATE_WHAT_IF_SCENARIOS,
  TXT_CREATE_WHAT_IF_SCENARIOS,
  TXT_NAME
} from '../../../../../shared/translations';
import * as yup from 'yup';
import { QuoteWizardInfo } from './QuoteWizardInfo';
import _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { ignoreTimeZone } from 'shared/utils';
import { AddDealPortInput } from 'api/graphql/dealPort';
import { createContext, useEffect, useState } from 'react';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import { CustomError } from 'components/CustomError';

interface QuoteWizardProps extends dealModel {
  onClose: () => void;
  afterSave: () => void;
}

const dealPortSchema = {
  portId: yup.string().required(),
  quantity: yup.number().min(0).nullable()
};

const splitQuantitySchema = {
  name: yup.string().required(),
  counterPartyId: yup.string().required(),
  quantity: yup.number().min(0),
  unloadingPortId: yup.string().required(),
  loadingPortId: yup.string().required(),
  productId: yup.string().required(),
  trafficSpecialistId: yup.string().nullable().default(null)
};

const dealSupplierSchema = {
  supplierId: yup.string().required(),
  applicableDealPortId: yup.string().required(),
  quantity: yup.number().nullable().min(0),
  incoTerm: yup.string().nullable().default(null)
};

const schema = yup.object().shape({
  name: yup.string().required(),
  isActive: yup.bool().required().default(true),
  isQuote: yup.bool().required().default(true),
  splitQuantities: yup.array().of(yup.object().shape(splitQuantitySchema)),
  suppliers: yup.array().of(yup.object().shape(dealSupplierSchema)),
  shipownerId: yup.string(),
  vesselId: yup.string().required(),
  loadingPorts: yup.array().of(yup.object().shape(dealPortSchema)),
  unloadingPorts: yup.array().of(yup.object().shape(dealPortSchema)),
  currency: yup.string().required(),
  massUnit: yup.string().required(),
  parentLaytimeCalculationId: yup.string().nullable()
});

interface QuoteWizardContextProps {
  parentLaytimeCalculationId?: string;
  isDisabled?: boolean;
  onCounterPartyChanged: (cParty?: counterPartyTypeModel) => void;
  onVesselChanged: (vessel?: vesselModel) => void;
  onCurrencyChanged: (currency?: string) => void;
  onLoadingPortChanged: (index: number) => (port: portModel | null) => void;
  onUnloadingPortChanged: (index: number) => (port: portModel | null) => void;
  onTermChanged: (index: number) => (term: string | null) => void;
  onQuantityChanged: (index: number) => (quantity?: number) => void;
  onProductChanged: (index: number) => (product?: productModel) => void;
  onTrafficAnalystChanged: (index: number) => (user?: applicationUserModel) => void;
}

export const QuoteWizardContext = createContext<QuoteWizardContextProps>({
  onCounterPartyChanged: (_) => {},
  onVesselChanged: (_) => {},
  onCurrencyChanged: (_) => {},
  onLoadingPortChanged: (_) => (_) => {},
  onUnloadingPortChanged: (_) => (_) => {},
  onTermChanged: (_) => (_) => {},
  onQuantityChanged: (_) => (_) => {},
  onProductChanged: (_) => (_) => {},
  onTrafficAnalystChanged: (_) => (_) => {}
});

export const QuoteWizard = (props: QuoteWizardProps) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const methods = useForm<AddDealInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      isActive: props.isActive === undefined ? true : props.isActive,
      isQuote: props.isQuote === undefined ? true : props.isQuote,
      shipownerId: props.shipowner?.id,
      shipowner: props.shipowner,
      vesselId: props.vessel?.id,
      vessel: props.vessel,
      loadingPorts: props.loadingPorts
        ? [...props.loadingPorts]
        : [
          {
            id: 'L-1'
          }
        ],
      unloadingPorts: props.unloadingPorts
        ? [...props.unloadingPorts]
        : [
          {
            id: 'U-1'
          }
        ],
      currency: props.currency || 'USD',
      massUnit: props.massUnit || 'MT',
      splitQuantities: props.splitQuantities
        ? props.splitQuantities.map((split) => _.omit(split, ['unloadingPort', 'loadingPort']))
        : [
          {
            id: `-1`,
            incoTerm: 'CIF',
            loadingPortId: 'L-1',
            unloadingPortId: 'U-1'
          }
        ],
      suppliers: props.suppliers
        ? props.suppliers.map((split) => _.omit(split, ['loadingPort']))
        : [
          {
            id: `-1`,
            applicableDealPortId: 'L-1'
          }
        ],
      parentLaytimeCalculationId: props.parentLaytimeCalculationId,
      scope: props.scope || 'CORE'
    }
  });

  const { handleSubmit } = methods;

  const { apolloErrorHandler } = useApolloErrorHandler();

  const { setValue, register, formState } = methods;

  const onCounterPartyChanged = (cParty?: counterPartyTypeModel) => {
    setValue('shipownerId', cParty?.id ?? '');
    setValue('shipowner', cParty);
    setValue(`splitQuantities.${0}.counterPartyId`, cParty?.id ?? '');
    setValue(`splitQuantities.${0}.counterParty`, cParty);
    setValue(`suppliers.${0}.supplierId`, cParty?.id ?? '');
    setValue(`suppliers.${0}.supplier`, cParty);
  };

  const onVesselChanged = (vessel?: vesselModel) => {
    setValue('vesselId', vessel?.id || '');
    setValue('vessel', vessel);
  };

  const onCurrencyChanged = (currency?: string) => {
    setValue('currency', currency || '');
  };

  const onLoadingPortChanged = (index: number) => {
    return (port: portModel | null) => {
      setValue(`loadingPorts.${index}.portId`, port?.id);
      setValue(`loadingPorts.${index}.port`, port ?? undefined);
    };
  };

  const onUnloadingPortChanged = (index: number) => {
    return (port: portModel | null) => {
      setValue(`unloadingPorts.${index}.portId`, port?.id);
      setValue(`unloadingPorts.${index}.port`, port ?? undefined);
    };
  };

  const onTermChanged = (index: number) => {
    return (term: string | null) => {
      setValue(`splitQuantities.${index}.incoTerm`, term === null ? '' : term);
    };
  };

  const onQuantityChanged = (index: number) => {
    return (quantity?: number) => {
      setValue(`splitQuantities.${index}.quantity`, quantity);
      setValue(`loadingPorts.${index}.quantity`, quantity);
      setValue(`unloadingPorts.${index}.quantity`, quantity);
    };
  };

  const onProductChanged = (index: number) => {
    return (product?: productModel) => {
      setValue(`splitQuantities.${index}.productId`, product?.id || '');
      setValue(`splitQuantities.${index}.product`, product);
    };
  };

  const onTrafficAnalystChanged = (index: number) => {
    return (user?: applicationUserModel) => {
      setValue(`splitQuantities.${index}.trafficSpecialistId`, user?.id);
      setValue(`splitQuantities.${index}.trafficSpecialist`, user);
    };
  };

  const [getCalc, { loading: fetchCalc }] = useLazyQuery<GetLaytimeCalculationsOutput>(getLaytimeCalculations(), {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.laytimeCalculations.length <= 0) {
        return;
      }
      const calc = data.laytimeCalculations[0];

      onCounterPartyChanged(calc.counterParty);

      onQuantityChanged(0)(calc.quantity);

      let deals: dealPortDeal[] | undefined = undefined;
      let isLoading = true;

      if (!_.isEmpty(calc.dealPort?.loadingDeals)) {
        deals = calc.dealPort?.loadingDeals;
      } else if (!_.isEmpty(calc.dealPort?.unloadingDeals)) {
        deals = calc.dealPort?.unloadingDeals;
        isLoading = false;
      }

      if (deals) {
        const deal = deals[0];
        onVesselChanged(deal.vessel);
        onCurrencyChanged(deal.currency);

        if (deal.splitQuantities && !_.isEmpty(deal.splitQuantities)) {
          let split = deal.splitQuantities[0];
          if (calc.dealPort?.portId) {
            const foundSplit = deal.splitQuantities.find(
              (x) =>
                (isLoading && x.loadingPort?.portId === calc.dealPort?.portId) ||
                (!isLoading && x.unloadingPort?.portId === calc.dealPort?.portId)
            );
            if (foundSplit) {
              split = foundSplit;
            }
          }

          const onPortChanged = isLoading ? onUnloadingPortChanged : onLoadingPortChanged;
          const port = isLoading ? split.unloadingPort?.port : split.loadingPort?.port;
          onPortChanged(0)(port || null);

          onTermChanged(0)(split.incoTerm || null);

          onProductChanged(0)(split.product);

          onTrafficAnalystChanged(0)(split.trafficSpecialist);
        }
      }

      if (calc.dealPort?.port) {
        const onPortChanged = isLoading ? onLoadingPortChanged : onUnloadingPortChanged;
        onPortChanged(0)(calc.dealPort?.port);
      }
    },
    onError: apolloErrorHandler
  });

  const [mutate, { loading, error: savingError }] = useMutation<dealModel>(props.id ? UPDATE_DEAL : ADD_DEAL, {
    onError: (error) => {
      apolloErrorHandler(error);
      setIsSaving(false);
    },
    onCompleted: () => {
      setTimeout(() => {
        props.afterSave();
        props.onClose();
        setIsSaving(false);
      }, 2000);
    }
  });

  const modifiedDealPortInput = (port: AddDealPortInput) => {
    return {
      ..._.omit(port, ['id', 'port', 'agent']),
      billOfLadingDate: ignoreTimeZone(port.billOfLadingDate),
      commencedDate: ignoreTimeZone(port.commencedDate),
      completedDate: ignoreTimeZone(port.completedDate),
      validNor: ignoreTimeZone(port.validNor),
      laycanEndDate: ignoreTimeZone(port.laycanEndDate),
      laycanStartDate: ignoreTimeZone(port.laycanStartDate),
      norTenderedDate: ignoreTimeZone(port.norTenderedDate),
      pendingToSync: false
    };
  };

  const onSave: SubmitHandler<AddDealInput> = (data) => {
    setErrorMessage(() => undefined);
    const options = ['counterParty', 'product', 'unloadingPortId', 'loadingPortId', 'canDelete'];
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    const variableInput = {
      ..._.omit(data, [
        'client',
        'shipowner',
        'supplier',
        'vessel',
        'product',
        'trafficSpecialist',
        'trader',
        'businessUnit',
        'loadingPorts',
        'unloadingPorts',
        'splitQuantities'
      ]),
      loadingPorts: data.loadingPorts
        ? data.loadingPorts.map((port) => ({ ...modifiedDealPortInput(port), strId: port.id }))
        : null,
      unloadingPorts: data.unloadingPorts
        ? data.unloadingPorts.map((port) => ({ ...modifiedDealPortInput(port), strId: port.id }))
        : null,
      splitQuantities: data.splitQuantities
        ? data.splitQuantities.map((split) => ({
          ..._.omit(split, options),
          strUnloadingPortId: split.unloadingPortId,
          strLoadingPortId: split.loadingPortId
        }))
        : undefined,
      suppliers: data.suppliers
        ? data.suppliers.map((supplier) => ({
          ..._.omit(supplier, ['supplier', 'canDelete', 'applicableDealPortId', 'applicableDealPort']),
          id: supplier.id?.startsWith('-') ? emptyGuid : supplier.id,
          dealId: props.id || emptyGuid,
          strApplicableDealPortId: supplier.applicableDealPortId
        }))
        : undefined
    };

    setIsSaving(true);
    if (props.id) {
      mutate({
        variables: {
          input: {
            id: props.id,
            ...variableInput,
            splitQuantities: variableInput.splitQuantities
              ? variableInput.splitQuantities.map((split) => ({
                ...split,
                id: split.id?.startsWith('-') ? emptyGuid : split.id,
                dealId: props.id
              }))
              : null
          }
        }
      });
      return;
    }

    mutate({
      variables: {
        input: {
          ...variableInput,
          splitQuantities: variableInput.splitQuantities
            ? variableInput.splitQuantities.map((split) => ({
              ..._.omit(split, ['id'])
            }))
            : null
        }
      }
    });
  };

  const getTitle = () => {
    let title = '';
    if (props.id) {
      if (props.parentLaytimeCalculationId) {
        title = t(TXT_UPDATE_WHAT_IF_SCENARIOS);
      } else {
        title = capitalizeFirstLetterEveryword(t(TXT_UPDATE_QUICK_CALC));
      }
    } else {
      if (props.parentLaytimeCalculationId) {
        title = t(TXT_CREATE_WHAT_IF_SCENARIOS);
      } else {
        title = capitalizeFirstLetterEveryword(t(TXT_CREATE_QUICK_CALCULATION));
      }
    }

    return title;
  };

  useEffect(() => {
    if (!!props.id || !props.parentLaytimeCalculationId) {
      return;
    }
    getCalc({
      variables: {
        where: {
          id: {
            eq: props.parentLaytimeCalculationId
          }
        }
      }
    });
  }, [props.parentLaytimeCalculationId, props.id]);

  const showFullContent = !!props.id || !props.parentLaytimeCalculationId;

  useEffect(() => {
    setErrorMessage(() =>
      savingError?.graphQLErrors
        .filter((x) => x.extensions)
        .map((x) => x.extensions!['message'] as string)
        .reduce((prev, curr) => `${prev}\n${curr}`, '')
    );
  }, [savingError]);

  return (
    <Dialog
      open={true}
      maxWidth={showFullContent ? 'lg' : 'sm'}
      fullWidth
      disableEscapeKeyDown={true}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose();
        }
      }}
      PaperProps={{
        sx: {
          maxHeight: '95vh'
        }
      }}
    >
      <AppBar position="relative">
        <Toolbar>{getTitle().toUpperCase()}</Toolbar>
      </AppBar>
      <DialogContent>
        {!!errorMessage ? (
          <CustomError
            sx={(theme) => ({
              marginBottom: theme.spacing(2)
            })}
          >
            {errorMessage}
          </CustomError>
        ) : null}
        {fetchCalc ? (
          <CircularProgress />
        ) : (
          <FormProvider {...methods}>
            <QuoteWizardContext.Provider
              value={{
                onCounterPartyChanged,
                onVesselChanged,
                onCurrencyChanged,
                onLoadingPortChanged,
                onUnloadingPortChanged,
                onTermChanged,
                onQuantityChanged,
                onProductChanged,
                onTrafficAnalystChanged,
                isDisabled: loading,
                parentLaytimeCalculationId: props.parentLaytimeCalculationId
              }}
            >
              <form
                id="frm-quote-wizard"
                onSubmit={handleSubmit(onSave)}
                style={{
                  marginTop: theme.spacing(1)
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={12} lg={12}>
                    {showFullContent ? (
                      <QuoteWizardInfo />
                    ) : (
                      <TextField
                        id="name"
                        label={capitalizeFirstLetterEveryword(t(TXT_NAME))}
                        fullWidth
                        {...register('name')}
                        onChange={(e) => {
                          setValue('name', e.target.value);
                          setValue(`splitQuantities.${0}.name`, e.target.value);
                        }}
                        error={!!formState.errors.name}
                        disabled={loading}
                      />
                    )}
                  </Grid>
                </Grid>
              </form>
            </QuoteWizardContext.Provider>
          </FormProvider>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'flex-end'
        }}
      >
        <Button onClick={props.onClose} color="secondary" disabled={loading || isSaving}>
          {t(TXT_CANCEL)}
        </Button>
        <Button type="submit" form="frm-quote-wizard" disabled={loading}>
          { (loading || isSaving) && <CircularProgress style={{ marginRight: '5px' }} size="20px" />}
          {t(loading ? TXT_SAVING : TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
