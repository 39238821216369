import { gql } from '@apollo/client';
import {
  applicationUserModel,
  businessUnitModel,
  counterPartyModel,
  dealPortModel,
  productModel,
  traderModel
} from 'model';

export interface AddDealPortInput
  extends Omit<dealPortModel, 'loadingDeals' | 'unloadingDeals' | 'eta' | 'etb' | 'etc'> {}

export interface UpdateSplitQuantityInput {
  id?: string;
  counterPartyId?: string;
  counterParty?: counterPartyModel;
  dealId?: string;
  quantity?: number;
  name?: string;
  unloadingPortId?: string;
  loadingPortId?: string;
  productId?: string;
  product?: productModel;
  incoTerm: string;
  trafficSpecialistId?: string;
  trafficSpecialist?: applicationUserModel;
  traderId?: string;
  trader?: traderModel;
  businessUnitId?: string;
  businessUnit?: businessUnitModel;
  canDelete?: boolean;
  parentSplitQuantityName?: string;
}

export interface UpdateDealPortInput extends AddDealPortInput {
  id: string;
}

export interface DeleteSplitQuantityInput {
  id: string;
}

export interface DeleteDealPortInput {
  id: string;
}

export type UpdateDealPortFormInput = {
  id: string;
  billOfLadingDate?: Date;
  norTenderedDate?: Date;
  commencedDate?: Date;
  completedDate?: Date;
  eta?: Date;
  etb?: Date;
  etc?: Date;
  tonnageOnBoard?: number;
  tonnagePending?: number;
  close?: boolean;
};

export const ADD_DEAL_PORT = gql`
  mutation addDealPort($input: AddDealPortInput!) {
    addDealPort(input: $input) {
      id
      portId
      billOfLadingDate
      validNor
    }
  }
`;

export const UPDATE_DEAL_PORT = gql`
  mutation updateDealPort($input: UpdateDealPortInput!) {
    updateDealPort(input: $input) {
      id
      portId
      billOfLadingDate
      validNor
    }
  }
`;

export const DELETE_SHIPMENT_PORT = gql`
  mutation deleteDealPort($input: DeleteDealPortInput!) {
    deleteDealPort(input: $input)
  }
`;
