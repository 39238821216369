import * as React from 'react';
import { Modal, Box, SxProps } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { LaytimeCalculationPdf } from './LaytimeCalculationPdf';
import { useDemurragePageState } from '../DemurragePageContext';
import { GetCalculationEventsOutput, GET_CALCULATION_EVENTS } from 'api';
import { useLazyQuery } from '@apollo/client';
import { useApolloErrorHandler } from 'hooks/useApolloErrorHandler';
import { useRecoilState } from 'recoil';
import { tenantState } from 'state/tenantState';

interface PrintPreviewProps {
  open: boolean;
  handleClose: () => void;
}

const style: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid grey',
  boxShadow: 24,
  p: 2
};

export const PrintPreview = ({ open, handleClose }: PrintPreviewProps) => {
  const state = useDemurragePageState();
  const [tenant] = useRecoilState(tenantState);
  const { apolloErrorHandler } = useApolloErrorHandler();

  const [getEvents, { loading, error, data, refetch }] = useLazyQuery<GetCalculationEventsOutput>(
    GET_CALCULATION_EVENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        calculationId: state.selectedLaytimeCalculationId
      },
      onError: apolloErrorHandler
    }
  );

  const fetch = () => {
    if (!refetch) {
      getEvents();
    } else {
      refetch();
    }
  };

  React.useEffect(() => {
    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {loading ? (
          <div>loading...</div>
        ) : (
          <Box sx={style}>
            <PDFViewer width="100%" height="100%" showToolbar>
              <LaytimeCalculationPdf
                state={state}
                report={data}
                tenant={tenant}
              />
            </PDFViewer>
          </Box>
        )}
        {error && <div>{error}</div>}
      </>
    </Modal>
  );
};
