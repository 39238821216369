import { gql } from '@apollo/client';
import {
  interruptionModel,
  laytimeCalculationModel,
  LaytimeCalculationResult,
  LaytimeSpecialCondition,
  OnceOnDemurrageCondition
} from 'model';
import { getInterruptionsFragment } from '../interruption';
import { getShexConditionsFragment, GetShexConditionsOutput } from '../shexCondition';

export interface AddLaytimeCalculationInput {
  dealPortId: string;
  counterPartyId: string;
  counterPartyTypeId: string;
  status?: string;
  statusComment?: string;
  currency?: string;
  dischargeRate?: number;
  demurragePerDay?: number;
  despatchPerDay?: number;
  turnTime?: number;
  specialCondition?: LaytimeSpecialCondition;
  onceOnDemurrageCondition?: OnceOnDemurrageCondition;
  percentage?: number;
  laycanStartDate?: Date;
  laycanEndDate?: Date;
  massUnit?: string;
  splitQuantityIds?: string[];
  dealSupplierId?: string;
  isReversibleCalc?: boolean;
}

export interface UpdateLaytimeCalculationInput extends AddLaytimeCalculationInput {
  id: string;
}

export interface OverrideFinalValueLaytimeCalculationInput {
  id: string;
  newValue?: number;
  result?: LaytimeCalculationResult;
}

export interface OverrideVesselInfoInput {
  laytimeCalculationId: string;
  laycanStartDateOverride?: Date;
  laycanEndDateOverride?: Date;
  quantityOverride?: number;
  validNorOverride?: Date;
  completedDateOverride?: Date;
  realLaytimeBeginsOverride?: Date;
  norTenderedDateOverride?: Date;
  commencedDateOverride?: Date;
  laytimeTimeEndsOverride?: Date;
  permittedTimeRoundingOverride?: number;
  deductionOffset?: number;
}

const overridableFields = `
  realLaytimeBeginsOverride
  laycanStartDateOverride
  laycanEndDateOverride
  quantityOverride
  validNorOverride
  completedDateOverride
  norTenderedDateOverride
  commencedDateOverride
  laytimeTimeEndsOverride
  permittedTimeRoundingOverride
  deductionOffset
`;

export const laytimeCalculationFragment = `
  dealPortId
  counterPartyTypeId
  counterPartyType {
    name
    id
  }
  counterPartyId
  counterParty {
    name
    id
    businessUnitId
  }
  status
  statusComment
  dischargeRate
  demurragePerDay
  currency
  despatchPerDay
  turnTime
  realLaytimeBegins
  totalTimeUsed
  lessInterruptions
  netTimeUsed
  mvOnDemurrage
  timeInDemurrage
  demurrageAmount
  timeInDespatch
  despatchAmount
  id
  createdDate
  permittedTime
  specialCondition
  onceOnDemurrageCondition
  hideRemarks
  isReversibleCalc
  reversedByCalcId
  percentage
  isCalculationOverriden
  laytimeCalculationResult
  amountBeforeShare
  sharedPercentage
  laycanStartDate
  laycanEndDate
  quantity
  massUnit
  splitQuantities {
    id
    loadingPortId
    unloadingPortId
    unloadingPort {
      id
      portId
      port {
        id
        name
        country
      }
    }
    loadingPort {
      id
      portId
      port {
        id
        name
        country
      }
    }
    productId
    product {
      id
      name
    }
    counterPartyId
    counterParty {
      id
      name
      businessUnitId
    }
  }
  dealSupplierId
  validNor
  completedDate
  norTenderedDate
  commencedDate
  ${overridableFields}
  version
`;

export const ADD_LAYTIME_CALCULATION = gql`
  mutation addLaytimeCalculation($input: AddLaytimeCalculationInput!) {
    addLaytimeCalculation(input: $input) {
      ${laytimeCalculationFragment}
    }
  }
`;

export interface UpdateLaytimeCalculationOutput {
  updateLaytimeCalculation: laytimeCalculationModel;
}
export const UPDATE_LAYTIME_CALCULATION = gql`
  mutation updateLaytimeCalculation($input: UpdateLaytimeCalculationInput!) {
    updateLaytimeCalculation(input: $input) {
      ${laytimeCalculationFragment}
    }
  }
`;

export interface DeleteLaytimeCalculationInput {
  id: string;
}

export const DELETE_LAYTIME_CALCULATION = gql`
  mutation deleteLaytimeCalculation($input: DeleteLaytimeCalculationInput!) {
    deleteLaytimeCalculation(input: $input)
  }
`;

export const OVERRIDE_LAYTIME_CALCULATION_FINAL_RESULT = gql`
  mutation overrideLaytimeCalculationFinalResult($input: OverrideLaytimeCalculationFinalResultInput!) {
    overrideLaytimeCalculationFinalResult(input: $input){
      ${laytimeCalculationFragment}
    }
  }
`;

export const COPY_LAYTIME_CALCULATION = gql`
  mutation copyCalculations($input: CopyCalculationsInput!) {
    copyCalculations(input: $input){
      calculation {
        ${laytimeCalculationFragment}
      }
      interruptions {
        ${getInterruptionsFragment}
      }
      shexConditions {
        ${getShexConditionsFragment}
      }
    }
  }
`;

export const OVERRIDE_VESSEL_INFO = gql`
  mutation overrideVesselInfo($input: OverrideVesselInfoInput!) {
    overrideVesselInfo(input: $input) {
      ${laytimeCalculationFragment}
    }
  }
`;

export interface OverrideFinalValueLaytimeCalculationOutput {
  overrideLaytimeCalculationFinalResult: laytimeCalculationModel;
}

export interface CopyCalculationsOutput {
  copyCalculations: CopyCalculationsData;
}

export interface CopyCalculationsData extends GetShexConditionsOutput {
  calculation: laytimeCalculationModel;
  interruptions: interruptionModel[];
}

export interface OverrideVesselInfoOutput {
  overrideVesselInfo: laytimeCalculationModel;
}
