/* eslint-disable indent */
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_PORT,
  TXT_COUNTERPARTY,
  TXT_SPECIAL_CONDITION,
  TXT_EVEN_IF_SOONER_COMMENCED,
  TXT_UNLESS_SOONER_COMMENCED,
  TXT_ONCEONDEMURRAGE_CONDITION,
  TXT_ALWAYS_ON_DEMURRAGE,
  TXT_NOT_ALWAYS_ON_DEMURRAGE,
  TXT_STATUS,
  TXT_CURRENCY,
  TXT_MODIFYING_LOCKED_CALC_VALIDATION,
  TXT_MASS_UNIT,
  TXT_MASS_UOM_OVERRIDE
} from '../../../../../../shared/translations';
import { VesselInfo } from './VesselInfo';
import { Interruptions } from './Interruptions';
import { Calculations } from './Calculations';
import { setProperty, useDemurragePageDispatch, useDemurragePageState } from '../DemurragePageContext';
import { textFieldDefaultProps } from 'components';
import { getStatusName } from 'shared/utils';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useGlobalStyles } from 'pages/globalStyles';
import { useState } from 'react';
import { ChangeStatusEditor } from '../ChangeStatusEditor';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import useAuth from 'hooks/useAuth';


export const DemurrageContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { demurrageDeal, selectedLaytimeCalculation } = useDemurragePageState();
  const dispatch = useDemurragePageDispatch();
  const globalStyles = useGlobalStyles();
  const [openStatusEditor, setOpenStatusEditor] = useState(false);
  const [auth] = useAuth();
  const { isGuest } = auth;

  const handleSpecialConditionChange = (e: any) => {
    dispatch(setProperty('specialCondition', e.target.value));
    showLockedCalcValidation();
  };

  const handleIsReversibleCalcChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(setProperty('isReversibleCalc', checked));
    showLockedCalcValidation();
  };

  const handleCurrencyChange = (e: any) => {
    dispatch(setProperty('currency', e.target.value));
    showLockedCalcValidation();
  };

  const handleUomChange = (e: any) => {
    dispatch(setProperty('massUnit', e.target.value));
    showLockedCalcValidation();
  };

  const handleOnceOnDemurrageConditionChange = (e: any) => {
    dispatch(setProperty('onceOnDemurrageCondition', e.target.value));
    showLockedCalcValidation();
  };

  const showLockedCalcValidation = () => {
    if (selectedLaytimeCalculation?.isCalculationOverriden) {
      enqueueSnackbar(
        <Typography variant="subtitle1">{_.capitalize(t(TXT_MODIFYING_LOCKED_CALC_VALIDATION))}</Typography>,
        {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          autoHideDuration: 10000
        }
      );
    }
  };

  const getPortName = () => {
    if (!selectedLaytimeCalculation || !selectedLaytimeCalculation.dealPortId) {
      return undefined;
    }

    const loadingPort = (demurrageDeal?.loadingPorts || []).find(
      (dealPort) => dealPort.id === selectedLaytimeCalculation.dealPortId
    );

    if (loadingPort?.port) {
      return loadingPort?.port?.name;
    }

    const unloadingPort = (demurrageDeal?.unloadingPorts || []).find(
      (dealPort) => dealPort.id === selectedLaytimeCalculation.dealPortId
    );

    return unloadingPort?.port?.name;
  };

  const isLoading = () : boolean | undefined => {
    if (!selectedLaytimeCalculation || !selectedLaytimeCalculation.dealPortId) {
      return undefined;
    }

    const loadingPort = (demurrageDeal?.loadingPorts || []).find(
      (dealPort) => dealPort.id === selectedLaytimeCalculation.dealPortId
    );

    return loadingPort?.port ? true : false;
  };

  const lblSpecialCondition = capitalizeFirstLetterEveryword(t(TXT_SPECIAL_CONDITION));
  const lblCurrency = capitalizeFirstLetterEveryword(t(TXT_CURRENCY));
  const lblOnceOndemurrageCondition = capitalizeFirstLetterEveryword(t(TXT_ONCEONDEMURRAGE_CONDITION));
  const lblUom = capitalizeFirstLetterEveryword(t(TXT_MASS_UNIT));
  if (!demurrageDeal) {
    return (
      <Box sx={{ width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  const isDisabled = !selectedLaytimeCalculation || selectedLaytimeCalculation.status !== 'DRAFT' || isGuest;

  return (
    <Box component="form" noValidate autoComplete="off">
      {openStatusEditor && selectedLaytimeCalculation && (
        <ChangeStatusEditor
          oldStatus={selectedLaytimeCalculation.status || 'DRAFT'}
          oldComment={selectedLaytimeCalculation.statusComment || ''}
          onClose={() => setOpenStatusEditor(false)}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={4}>
          <TextField
            {...textFieldDefaultProps}
            id="outlined-required"
            label={capitalizeFirstLetterEveryword(t(TXT_PORT))}
            value={getPortName() || ''}
            InputProps={{
              readOnly: true
            }}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <TextField
            {...textFieldDefaultProps}
            id="outlined-required"
            label={capitalizeFirstLetterEveryword(t(TXT_COUNTERPARTY))}
            value={selectedLaytimeCalculation?.counterParty?.name || ''}
            InputProps={{
              readOnly: true
            }}
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          {!demurrageDeal.isQuote ? (
            <TextField
              {...textFieldDefaultProps}
              fullWidth
              label={capitalizeFirstLetterEveryword(t(TXT_STATUS))}
              value={selectedLaytimeCalculation?.status ? getStatusName(selectedLaytimeCalculation.status, t) : ''}
              InputProps={{
                readOnly: true,
                endAdornment: selectedLaytimeCalculation && !isGuest && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="calculation-status"
                      disabled={!selectedLaytimeCalculation}
                      className={globalStyles.editButton}
                      component="div"
                      onClick={() => setOpenStatusEditor(true)}
                      size="small"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="standard"
              helperText={selectedLaytimeCalculation?.statusComment}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="lbl-once-ondemurrage-condition">{lblOnceOndemurrageCondition}</InputLabel>
            <Select
              id="once-ondemurrage-condition"
              labelId="lbl-once-ondemurrage-condition"
              label={lblOnceOndemurrageCondition}
              value={selectedLaytimeCalculation?.onceOnDemurrageCondition ?? ''}
              disabled={isDisabled}
              onChange={handleOnceOnDemurrageConditionChange}
            >
              <MenuItem value={'ALWAYS'}>{capitalizeFirstLetterEveryword(t(TXT_ALWAYS_ON_DEMURRAGE))}</MenuItem>
              <MenuItem value={'SOMETIMES'}>{capitalizeFirstLetterEveryword(t(TXT_NOT_ALWAYS_ON_DEMURRAGE))}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="lbl-special-condition">{lblSpecialCondition}</InputLabel>
            <Select
              id="special-condition"
              labelId="lbl-special-condition"
              label={lblSpecialCondition}
              value={selectedLaytimeCalculation?.specialCondition ?? ''}
              disabled={isDisabled}
              onChange={handleSpecialConditionChange}
            >
              <MenuItem value={'EVEN'}>{capitalizeFirstLetterEveryword(t(TXT_EVEN_IF_SOONER_COMMENCED))}</MenuItem>
              <MenuItem value={'UNLESS'}>{capitalizeFirstLetterEveryword(t(TXT_UNLESS_SOONER_COMMENCED))}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="lbl-currency">{lblCurrency}</InputLabel>
              <Select
                id="currency"
                labelId="lbl-currency"
                label={lblCurrency}
                value={selectedLaytimeCalculation?.currency ?? ''}
                disabled={isDisabled}
                onChange={handleCurrencyChange}
              >
                <MenuItem value={'USD'}>USD</MenuItem>
                <MenuItem value={'EUR'}>EUR</MenuItem>
                <MenuItem value={'CAD'}>CAD</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="lbl-uom">{lblUom}</InputLabel>
              <Select
                id="uom"
                labelId="lbl-uom"
                label={lblUom}
                value={
                  _.isEmpty(selectedLaytimeCalculation?.massUnit)
                    ? demurrageDeal.massUnit
                    : selectedLaytimeCalculation?.massUnit
                }
                disabled={isDisabled}
                onChange={handleUomChange}
              >
                <MenuItem value={'MT'}>MT</MenuItem>
                <MenuItem value={'ST'}>ST</MenuItem>
              </Select>
              <FormHelperText sx={{ textAlign: 'right' }}>
                {_.isEmpty(selectedLaytimeCalculation?.massUnit) ? '' : TXT_MASS_UOM_OVERRIDE}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
        {!isLoading() && (
            <FormControlLabel
                label={'' + capitalizeFirstLetterEveryword(t('Is Reversible Calc'))}
                control={
                  <Checkbox
                    checked={!!selectedLaytimeCalculation?.isReversibleCalc}
                    onChange={handleIsReversibleCalcChange}
                    disabled ={!selectedLaytimeCalculation || selectedLaytimeCalculation.status !== 'DRAFT' || isGuest}
                  />
                }
            />
            )}
            {isLoading() && (
              <div>{selectedLaytimeCalculation?.reversedByCalcId ? 'This calculation is part of a reversed calculation' : ''}</div>
            )}
        </Grid>
      </Grid>
      <VesselInfo />
      <Interruptions />
      <Calculations />
    </Box>
  );
};
