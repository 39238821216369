import { UpdateLaytimeCalculationInput } from 'api/graphql';
import { UpdateShexConditionsOutput } from 'api/graphql/shexCondition';
import { t } from 'i18next';
import _ from 'lodash';
import {
  dealModel,
  dealPortModel,
  laytimeCalculationModel,
  LaytimeCalculationStatus,
  SecurityRoles,
  SecurityRoleTranslations,
  splitQuantityModel,
  statementOfFactStatus
} from 'model';
import moment2, * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { ShexCondition } from 'pages/DemurragePage/DemurrageContent/ShexConditionsEditor/ShexConditionsEditor';
import { format2Digits } from 'pages/DemurragePage/utils';
import { isOutsideLaycan } from '../../../../shared/functions/laycanUtils';
import {
  TXT_AGREED,
  TXT_DRAFT,
  TXT_INVOICED,
  TXT_NEGOTIATING,
  TXT_NOT_APPLICABLE,
  TXT_READY,
  TXT_USED
} from '../../../../shared/translations';
import { capitalizeFirstLetterEveryword } from './stringFunctions';

momentDurationFormatSetup(moment);

export const getDuration = (value: number) => {
  return moment.duration(value, 'minutes');
};

export const formatDuration = (value: number) => {
  return getDuration(value).format('dd:hh:mm', {
    trim: false
  });
};

export const getDateFormat = (): string => {
  return moment.localeData().longDateFormat('L');
};

export const getTimeFormat = (): string => {
  return 'HH:mm';
};

export const getDateTimeFormat = (): string => {
  return `${getDateFormat()} ${getTimeFormat()}`;
};

export const validateEmail = (email?: string) => {
  const pattern =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
  return !!email && email.match(pattern) !== null;
};

export const formatCurrency = (value: number, currency: string) => {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(value);
};

export const formatNumber = (value?: number) => {
  if (!value) {
    return '';
  }
  return new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value);
};

export const ignoreTimeZone = (date?: Date) => {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  return moment.utc([date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()]).toDate();
};

export const updateDatePart = (date?: Date, newDate?: Date) => {
  if (!newDate) {
    return undefined;
  }
  if (!date) {
    return newDate;
  }
  const resp = newDate;
  resp.setHours(date.getHours(), date.getMinutes());
  return resp;
};

export const updateTimePart = (date?: Date, time?: string) => {
  if (!date) {
    return undefined;
  }
  if (!time) {
    return date;
  }
  const timeArray = time.split(':');
  if (timeArray.length >= 2) {
    const hours = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    if (!Number.isNaN(hours) && !Number.isNaN(minutes)) {
      const resp = date;
      resp.setHours(hours, minutes);
      return resp;
    }
  }
  return undefined;
};

export const strToNumber = (value?: string) => {
  const number = _.toNumber(value);
  return _.isNaN(number) ? undefined : number;
};

export const getMinutesFromString = (time?: string) => {
  const arr = (time || '').split(':');
  if (arr.length === 2) {
    const hours = parseInt(arr[0]);
    const minutes = parseInt(arr[1]);
    return hours * 60 + minutes;
  }
  return undefined;
};

export const getStringTimeFromMinutes = (value?: number) => {
  if (value === undefined || value === null) {
    return undefined;
  }
  const hours = Math.trunc(value / 60);
  const minutes = value % 60;
  return format2Digits(hours) + ':' + format2Digits(minutes);
};

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getReceivers = (deal: dealModel) => {
  let receivers: string[] = [];

  if (!_.isEmpty(deal.splitQuantities)) {
    receivers = [
      ...receivers,
      ...deal.splitQuantities!.filter((split) => !!split.counterParty?.name).map((split) => split.counterParty!.name!)
    ];
  }

  const uniqueReceivers = Array.from(new Set(receivers));
  return uniqueReceivers.join(', ');
};

export const getProducts = (
  deal: dealModel | undefined,
  laytimeCalc: laytimeCalculationModel | undefined = undefined
): string | undefined => {
  if (!deal) {
    return '';
  }

  const splitQuantities = deal.splitQuantities || [];

  // Receivers
  if (!_.isEmpty(laytimeCalc?.splitQuantities)) {
    return _.uniq(laytimeCalc?.splitQuantities?.map((split) => split.product?.name)).join(', ');
  } else if (laytimeCalc) {
    const products = splitQuantities
      .filter(
        (x: splitQuantityModel) =>
          x.loadingPortId === laytimeCalc.dealPortId || x.unloadingPortId === laytimeCalc.dealPortId
      )
      .map((y: splitQuantityModel) => y.product?.name ?? '');
    const uniqueProds = Array.from(new Set(products));
    return uniqueProds.join(', ');
  } else {
    const products = splitQuantities.map((y: splitQuantityModel) => y.product?.name ?? '');
    const uniqueProds = Array.from(new Set(products));
    return uniqueProds.join(', ');
  }
  return '';
};

export const getPort = (
  deal: dealModel | undefined,
  laytimeCalc: laytimeCalculationModel | undefined
): dealPortModel | undefined => {
  if (!deal || !laytimeCalc) {
    return undefined;
  }
  const collection = (deal.loadingPorts || []).concat(deal.unloadingPorts || []);
  return collection.find((dealPort) => dealPort.id === laytimeCalc.dealPortId);
};

export const getShexConditions = (shexConditions: ShexCondition[] | undefined): string => {
  const shexDescList = shexConditions?.filter((x) => x.description != 'FIXED')?.map((x) => x.description) ?? [];
  const shexSummary = Array.from(new Set(shexDescList)).join(', ');
  return shexDescList.length === 0 ? 'SSHINC' : shexSummary;
};

export function getUpdateLaytimeCalculationInput(calculation: laytimeCalculationModel): UpdateLaytimeCalculationInput {
  return {
    dealPortId: calculation.dealPortId!,
    id: calculation.id!,
    counterPartyId: calculation.counterParty!.id!,
    status: calculation.status,
    statusComment: calculation.statusComment,
    counterPartyTypeId: calculation.counterPartyType!.id!,
    demurragePerDay: calculation.demurragePerDay,
    currency: calculation.currency,
    despatchPerDay: calculation.despatchPerDay,
    dischargeRate: calculation.dischargeRate,
    turnTime: calculation.turnTime,
    specialCondition: calculation.specialCondition,
    onceOnDemurrageCondition: calculation.onceOnDemurrageCondition,
    percentage: calculation.percentage,
    laycanStartDate: calculation.laycanStartDate,
    laycanEndDate: calculation.laycanEndDate,
    massUnit: calculation.massUnit,
    splitQuantityIds: (calculation.splitQuantities || []).map((split) => split.id!),
    dealSupplierId: calculation.dealSupplierId,
    isReversibleCalc: calculation.isReversibleCalc
  };
}

export const getUpdateShexConditionsInput = (conditions: ShexCondition[], replaceIds: boolean = true) => {
  const NEW_ID = '00000000-0000-0000-0000-000000000000';
  return conditions.map((x) => ({
    description: x.description,
    remarks: x.remarks,
    laytimeCalculationId: x.laytimeCalculationId,
    fromDayOfWeek: x.fromDayOfWeek,
    fromTime: getMinutesFromString(x.fromTime),
    fromDate: ignoreTimeZone(x.fromDate),
    untilDayOfWeek: x.untilDayOfWeek,
    untilTime: getMinutesFromString(x.untilTime),
    untilDate: ignoreTimeZone(x.untilDate),
    shexConditionType: x.shexConditionType,
    ignore: x.ignore,
    id: replaceIds && x.id?.includes('new-') ? NEW_ID : x.id
  }));
};

export const getStatusName = (status: LaytimeCalculationStatus, t: any) => {
  let result = TXT_DRAFT;

  if (status === 'AGREED') {
    result = TXT_AGREED;
  }

  if (status === 'PROCESSED') {
    result = TXT_INVOICED;
  }

  if (status === 'NEGOTIATING') {
    result = TXT_NEGOTIATING;
  }

  if (status === 'NOT_APPLICABLE') {
    result = TXT_NOT_APPLICABLE;
  }

  return capitalizeFirstLetterEveryword(t(result));
};

export const getSOFStatusName = (status: statementOfFactStatus, t: any) => {
  let result = TXT_DRAFT;

  if (status === 'READY_TO_USE') {
    result = TXT_READY;
  }

  if (status === 'USED_BY_LAYTIME_CALCULATION') {
    result = TXT_USED;
  }

  return capitalizeFirstLetterEveryword(t(result));
};

export function getShexConditionsFromResponse(response: UpdateShexConditionsOutput): ShexCondition[] {
  return (response.updateShexConditions || []).map((x) => ({
    ...x,
    fromTime: getStringTimeFromMinutes(x.fromTime),
    untilTime: getStringTimeFromMinutes(x.untilTime)
  }));
}

export const getPorts = (dealPorts: dealPortModel[]) => {
  let ports: string[] = [];

  if (!_.isEmpty(dealPorts)) {
    ports = [...dealPorts!.filter((dealPort) => !!dealPort.port?.name).map((dealPort) => dealPort.port!.name!)];
  }

  return ports;
};

export const getBLDate = (dealPorts: dealPortModel[]) => {
  let dates: Date[] = [];

  if (!_.isEmpty(dealPorts)) {
    dates = [
      ...dealPorts!.filter((dealPort) => !!dealPort.billOfLadingDate).map((dealPort) => dealPort.billOfLadingDate!)
    ];
  }
  return dates && dates.length > 0 ? moment2(dates[0]).format('L') : '';
};

export const getLoadingQuantity = (dealPorts: dealPortModel[]) => {
  let quantity: number = 0;

  if (!_.isEmpty(dealPorts)) {
    quantity = dealPorts.reduce((partialSum, a) => partialSum + (a.quantity ? a.quantity : 0), 0);
  }
  return quantity;
};

export const addSeconds = (date: Date | undefined, seconds: number) : Date | undefined => {
  if (!date) {
    return date;
  }

  const dateCopy = new Date(date);
  dateCopy.setSeconds(dateCopy.getSeconds() + seconds);
  return dateCopy;
};

export const isVesselOutsideLaycan = (
  dealPort?: dealPortModel,
  selectedLaytimeCalculation?: laytimeCalculationModel
) => {
  const validNor = selectedLaytimeCalculation?.validNorOverride ?? dealPort?.validNor;
  const norTenderedDate = selectedLaytimeCalculation?.norTenderedDateOverride ?? dealPort?.norTenderedDate;

  const laycanStartDate = selectedLaytimeCalculation?.laycanStartDateOverride ?? dealPort?.laycanStartDate;
  let laycanEndDate = selectedLaytimeCalculation?.laycanEndDateOverride ?? dealPort?.laycanEndDate;

  const rawNorTender = validNor || norTenderedDate;

  laycanEndDate = addSeconds(laycanEndDate, (24*60*60) - 1); // +1 day - 1 sec = 23:59:59

  return isOutsideLaycan(
    rawNorTender,
    selectedLaytimeCalculation?.laycanStartDateOverride ?? laycanStartDate,
    selectedLaytimeCalculation?.laycanEndDateOverride ?? laycanEndDate
  );
};

export const getSecurityRolesText = (role: SecurityRoles | undefined) => {
  if (!role) return '';

  return capitalizeFirstLetterEveryword(t(SecurityRoleTranslations[role]));
};
